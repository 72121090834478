import React from 'react'
import PropTypes from 'prop-types'

const SideBar = ({ children }) => <aside>{children}</aside>

SideBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default SideBar
