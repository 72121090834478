import React from 'react'
import { IconContext } from 'react-icons'
import { Link } from 'gatsby'
import { FaBookReader, FaMapMarkerAlt, FaYoutube } from 'react-icons/fa'
import PropTypes from 'prop-types'
import styles from './articleButton.module.css'

export const getFontAwesomeComponent = icon => {
  let fa
  switch (icon) {
    case 'video':
      fa = <FaYoutube />
      break
    case 'read':
      fa = <FaBookReader />
      break
    case 'info':
      fa = <FaMapMarkerAlt />
      break
    default:
      fa = null
  }
  return fa
}

const ArticleButton = ({
  icon,
  url,
  color,
  text,
  currentCategory,
  videoId,
}) => {
  const articleIcon = getFontAwesomeComponent(icon)
  return (
    <Link
      className={styles.articleButton}
      to={url}
      style={{ backgroundColor: color }}
      state={{ currentCategory: currentCategory, videoId: videoId }}
    >
      <IconContext.Provider value={{ size: '25px', color: '#fff' }}>
        {articleIcon}
      </IconContext.Provider>
      <div className={styles.label}>{text}</div>
    </Link>
  )
}

ArticleButton.propTypes = {
  icon: PropTypes.string,
  url: PropTypes.string,
  videoId: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  currentCategory: PropTypes.string,
}

export default ArticleButton
