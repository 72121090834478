import React from 'react'
import Footer from '../footer/footer'
import Navigation from '../navigation/navigation'
import PropTypes from 'prop-types'
import styles from './twoColumnLayout.module.css'

const TwoColumnLayout = ({ children }) => {
  return (
    <div className={styles.site}>
      <div className={styles.siteInner}>
        <Navigation />
        <div className={styles.siteContent}>{children}</div>
      </div>
      <Footer />
    </div>
  )
}

TwoColumnLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default TwoColumnLayout
