import { get } from 'lodash'

export const getCurrentVideo = (allVideos, postVideoId) => {
  return allVideos
    .filter(video => {
      return video.node.videoId === postVideoId
    })
    .map(video => {
      return {
        thumb: video.node.localThumbnail.childImageSharp.fluid,
        id: video.node.videoId,
      }
    })
}

export const getRelatedIDs = (postCategories, allPosts, postVideoId) =>
  Array.from(
    new Set(
      postCategories
        .map(postCategory => {
          return allPosts.filter(post => post.categories.includes(postCategory))
        })
        .reduce((prev, curr) => prev.concat(curr))
        .filter(post => post.videoId !== postVideoId)
    )
  ).map(post => post.videoId)

export const getIdsByCategory = (allPosts, currentCategory) => {
  if (!currentCategory) {
    return []
  }
  return allPosts
    .filter(post =>
      post.categories.find(category => category === currentCategory)
    )
    .map(post => post.videoId)
}

export const getVideosByIds = (ids, allVideos) =>
  allVideos
    .filter(video => ids.find(id => video.node.videoId === id))
    .map(video => {
      return {
        thumb: video.node.localThumbnail.childImageSharp.fluid,
        id: video.node.videoId,
      }
    })

export const getAllVideos = (allVideos, allPosts) => {
  const filteredVideos = allPosts.map(post =>
    allVideos.find(video => {
      return video.node.videoId === post.videoId
    })
  )
  return filteredVideos.map(video => {
    return {
      thumb: get(video, 'node.localThumbnail.childImageSharp.fluid'),
      id: get(video, 'node.videoId'),
    }
  })
}

//Using the Fisher-Yates Algorithm to randomize array
export const randomizeVideos = array => {
  const arrayToShuffle = array.slice()
  for (let i = arrayToShuffle.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = arrayToShuffle[i]
    arrayToShuffle[i] = arrayToShuffle[j]
    arrayToShuffle[j] = temp
  }
  return arrayToShuffle
}
