import React from 'react'
import PropTypes from 'prop-types'
import styles from './articleTitle.module.css'

const ArticleTitle = ({ children }) => (
  <h1 className={styles.articleTitle}>{children}</h1>
)
ArticleTitle.propTypes = {
  children: PropTypes.string,
}
export default ArticleTitle
