import { sortBy } from 'lodash'
export const computeUniqueCategories = allCategories => {
  const arrayWithDuplicates = allCategories
    .map(post => post.categories.map(category => category))
    .reduce((prev, curr) => prev.concat(curr))

  const uniqueArray = Array.from(new Set(arrayWithDuplicates)).sort()

  return uniqueArray.map(category => {
    return {
      display: `${category} (${
        arrayWithDuplicates.filter(element => element === category).length
      })`,
      value: category,
    }
  })
}

export const getDestinationPlaceIDs = (distances, placeId) => {
  const originIndex = distances.origin_ids.findIndex(
    origin => origin === placeId
  )

  const relevantDistances = distances.rows[originIndex].elements

  const destinations = relevantDistances
    .map((data, index) => {
      if (data.status === 'OK' && data.distance.value <= 160934) {
        return { distance: data.distance.value, index }
      }
    })
    .filter(result => result !== undefined)

  const sortedDestinations = sortBy(destinations, 'distance')

  return sortedDestinations.map(
    destination => distances.destination_ids[destination.index]
  )
}
