import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styles from './suggestedVideoThumb.module.css'

export const SuggestedVideoThumb = ({ id, thumb }) => {
  return (
    <Link
      to="/videos"
      className={styles.videoThumbContainer}
      state={{ videoId: id }}
    >
      <div className={styles.videoThumb}>
        <Img fluid={thumb} />
      </div>
    </Link>
  )
}

const SuggestedVideos = ({ videos }) => {
  const suggestedVideos = videos.map((video, index) => {
    return <SuggestedVideoThumb key={index} id={video.id} thumb={video.thumb} />
  })
  return (
    <div className={styles.suggestedVideosContainer}>
      <h3 className={styles.suggestedVideosHeader}>Suggested Videos</h3>
      <div className={styles.suggestedVideos}>{suggestedVideos}</div>
    </div>
  )
}

SuggestedVideos.propTypes = {
  videos: PropTypes.array,
}

SuggestedVideoThumb.propTypes = {
  id: PropTypes.string,
  thumb: PropTypes.object,
}
export default SuggestedVideos
