import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'

const VideoContext = React.createContext()
const VideoProvider = VideoContext.Provider

export const VideoData = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allYoutubeVideo(
        sort: { fields: publishedAt, order: DESC }
        filter: {
          videoId: {
            nin: [
              "4fZYZoE2l1k"
              "ML7tL5UXR08"
              "dCF9kbtAqkQ"
              "0IiLM0VQKl4"
              "FoojQOukNzs"
            ]
          }
        }
      ) {
        edges {
          node {
            id
            description
            title
            videoId
            publishedAt
            privacyStatus
            thumbnail {
              height
              width
              url
            }
            localThumbnail {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <VideoProvider value={data.allYoutubeVideo.edges}>{children}</VideoProvider>
  )
}

VideoData.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default VideoContext
